import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from "@angular/forms";

import {AppComponent} from './app.component';
import {RouterModule, Routes} from "@angular/router";
import {StartpageComponent} from './startpage/startpage.component';
import {HeaderComponent} from './header/header.component';
import {MainnavComponent} from './mainnav/mainnav.component';
import {WuermOverviewComponent} from './content/wuerm-overview/wuerm-overview.component';
import {MapBoxComponent} from "./map-box/map-box.component";
import {HttpClientModule} from "@angular/common/http";
import {MapBoxPageviewComponent} from './content/map-box-pageview/map-box-pageview.component';
import {ArticleComponent} from './content/article/article.component';
import {SearchComponent} from "./search/search.component";
import {SearchApiService} from "./services/search-api/search-api.service";
// Swiper
import {SwiperConfigInterface, SwiperModule} from 'ngx-swiper-wrapper';
import {ArticlePopupComponent} from './content/article/article-popup/article-popup.component';
import {FooterComponent} from './footer/footer.component';
import {FeedbackComponent} from "./feedback/feedback.component";
import {FeedbackFormService} from "./services/feedback/feedbackform.service";
import {QuizComponent} from './content/quiz/quiz.component';
import {QuizPopupComponent} from './content/quiz/quiz-popup/quiz-popup.component';
import {InfoComponent} from "./info/info.component";
import {GalleryPopupComponent} from './gallery-popup/gallery-popup.component';
import {EchtzeitproxyService} from "./services/echtzeitproxy/echtzeitproxy.service";
import {ImprintComponent} from "./imprint/imprint.component";
import {DateprotectionComponent} from "./dateprotection/dateprotection.component";

import {TransferHttpCacheModule} from '@nguniversal/common';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

// Routes definition
const routes: Routes = [
  {
    path: '',
    component: StartpageComponent
  },
  {
    path: 'wuermlehrpfad-uebersicht',
    component: WuermOverviewComponent
  },
  {
    path: 'interaktive-map',
    component: MapBoxPageviewComponent
  },
  {
    path: 'article/:id',
    component: ArticleComponent
  },
  {
    path: 'suche',
    component: SearchComponent
  },
  {
    path: 'feedback',
    component: FeedbackComponent
  },
  {
    path: 'info',
    component: InfoComponent
  },
  {
    path: 'datenschutz',
    component: DateprotectionComponent
  },
  {
    path: 'impressum',
    component: ImprintComponent
  }
];

const routesOptions = {
  useHash: false
};

@NgModule({
  declarations: [
    AppComponent,
    StartpageComponent,
    HeaderComponent,
    MainnavComponent,
    WuermOverviewComponent,
    MapBoxComponent,
    MapBoxPageviewComponent,
    ArticleComponent,
    SearchComponent,
    ArticlePopupComponent,
    FooterComponent,
    FeedbackComponent,
    QuizComponent,
    QuizPopupComponent,
    FeedbackComponent,
    InfoComponent,
    GalleryPopupComponent,
    ImprintComponent,
      DateprotectionComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'wuermlehrpfad'}),
    RouterModule.forRoot(routes, routesOptions),
    HttpClientModule,
    // Swiper
    SwiperModule,
    ReactiveFormsModule,
    TransferHttpCacheModule
  ],
  providers: [
    SearchApiService,
    FeedbackFormService,
    EchtzeitproxyService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
