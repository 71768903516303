import {Component, Input, OnInit} from '@angular/core';
import {SharingService} from "../../../services/system/sharing.service";

@Component({
  selector: 'wu-quiz-popup',
  templateUrl: './quiz-popup.component.html',
  styleUrls: ['./quiz-popup.component.scss']
})
export class QuizPopupComponent implements OnInit {

  @Input() quizData: any;

  constructor(private sharingService: SharingService) {
  }

  ngOnInit() {
  }

  onClosePopup() {
    let sharingData = {
      quizPopupClosed: true
    };
    this.sharingService.emitChange(sharingData);
  }

}
