import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http"
import {Observable} from "rxjs/Observable";


@Injectable()
export class FeedbackFormService {

  constructor(private _http: HttpClient) {
  }

  public sendMail(data): Observable<any> {
    return this._http.post("https://api.wuermentdecken.de/mail", {
      //?text="+data.text+"&m1="+data.m1+"&m2="+data.m2+"&m3="+data.m3
      text: data.message,
      m1: data.m1,
      m1message: data.m1Message,
      m2: data.m2,
      m2message: data.m2Message,
      m3: data.m3,
      m3message: data.m3Message,
      m4: data.m4,
      m4message: data.m4Message,
      m5: data.m5,
      m5message: data.m5Message,
      m6: data.m6,
      m6message: data.m6Message,
      m7: data.m7,
      m7message: data.m7Message,
      m8: data.m8,
      m8message: data.m8Message,
      user_agent: window.navigator.userAgent
    });
  }

}
