import {Component, HostListener, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {ContentService} from "../../services/content/content.service";
import {SharingService} from "../../services/system/sharing.service";
import {ActivatedRoute} from "@angular/router";
import {SystemService} from "../../services/system/system.service";
import {isPlatformBrowser} from "@angular/common";
import {SeoService} from "../../services/seo/seo.service";

@Component({
  selector: 'wu-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {

  @Input() articleId: number = 9;

  articleItem;

  configSwiper;
  imageActiveIndex: number = 0;

  showSolution: boolean = false;

  quizPageIndex: number = 0;
  swiperIndex: number = 0;

  isMobileDevice: boolean = false;
  isViewMode: number = 0;
  slidesItems: Array<any> = [];
  galleryPopupActive: boolean = false;

  constructor(private contentService: ContentService,
              private route: ActivatedRoute,
              private seoService: SeoService,
              @Inject(PLATFORM_ID) private platformId: Object,
              private sharingService: SharingService) {

  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (isPlatformBrowser(this.platformId)) {
      this.checkDeviceType();
    }
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange() {
    if (isPlatformBrowser(this.platformId)) {
      this.checkDeviceType();
    }
  }

  checkDeviceType() {
    this.isMobileDevice = SystemService.checkDeviceType(this.platformId);

    let isDesktop = window.matchMedia("(min-width: 1000px)").matches,
      isHybrid = window.matchMedia("(max-width: 1000px)").matches,
      isMobile = window.matchMedia("(max-width: 800px)").matches;

    if (isDesktop) {
      this.isViewMode = 0;
    }

    if (isHybrid) {
      this.isViewMode = 1;
    }

    if (isMobile) {
      this.isViewMode = 2;
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    let scrollBtn = document.getElementById('scrollToTop'),
      currentScrollPos = window.scrollY;
    if (scrollBtn !== null && currentScrollPos > 300) {
      scrollBtn.style.opacity = '1';
    } else if (scrollBtn !== null && currentScrollPos <= 300) {
      scrollBtn.style.opacity = '0';
    }
  }

  ngOnInit() {

    this.route.queryParams
      .subscribe((params: any) => {
        if (params.hasOwnProperty('stationid')) {
          this.articleId = parseInt(params.stationid, 10);

          if (isPlatformBrowser(this.platformId)) {
            this.checkDeviceType();
          }
          this.initArticle();

          if (params.hasOwnProperty('moduleid')) {
            let moduleId = params.moduleid;
            setTimeout(() => {
              this.onScrollToModule(moduleId);
            }, 500);
          }
        } else if (params.hasOwnProperty('stationid') === undefined) {
          if (isPlatformBrowser(this.platformId)) {
            this.checkDeviceType();
          }
          this.initArticle();
        }
      }, (error: any) => {
        console.log('no params');
        if (isPlatformBrowser(this.platformId)) {
          this.checkDeviceType();
        }
        this.initArticle();
      });
  }

  ngOnChanges() {
    this.initArticle();
  }

  initArticle() {
    this.contentService.getStationSingle(this.articleId).subscribe((result) => {
      if (result !== undefined && result !== null) {
        this.articleItem = result;

        if (this.articleItem !== undefined) {
          this.initComponentMetatags();
        }

        this.initSwiper();

        setTimeout(() => {
          this.initCollapsableContent();
        }, 300);
      } else {
        this.articleItem = null;
      }

    });
  }

  initComponentMetatags() {
    let metatagTexts = {
      "title": this.articleItem.name,
      "description": this.articleItem.description !== undefined && this.articleItem.description !== null ? this.articleItem.description.replace(/<[^>]*>?/gm, '') : 'Digitaler Würmlehrpfad der Landeshauptstadt München'
    };

    this.seoService.createMetatags(metatagTexts);
  }

  initSwiper(): void {
    this.configSwiper = {
      observer: false,                // Set to to true to enable automatic update calls.
      direction: 'horizontal',               // Direction of the Swiper (Default: 'horizontal').
      threshold: 0,               // Distance needed for the swipe action (Default: 0).
      spaceBetween: 10,            // Space in pixels between the Swiper items (Default: 0).
      slidesPerView: 1,           // Number of the items per view or 'auto' (Default: 1).
      centeredSlides: false,          // Align active item on center not left (Default: false).
      initialSlide: 0,
      keyboard: false,
      mousewheel: false,
      scrollbar: false,
      navigation: true,
      pagination: false
    };

    if (this.isMobileDevice) {
      this.configSwiper.navigation = false;
    }
  }

  onIndexChange(index: number): void {
    this.imageActiveIndex = index;
  }

  onQuizIndexChange(index: number): void {
    this.quizPageIndex = index;
  }

  onAnkerLinkClick(event) {
    let clickedAnker = event.target,
      elementToScrollId = clickedAnker.dataset.ankerlink,
      elementToScroll = <HTMLElement>document.getElementById(elementToScrollId);

    let scrollContainer = <HTMLDivElement>document.getElementsByClassName('container-main')[0];

    elementToScroll.scrollIntoView();
  }

  onScrollToModule(moduleId: number) {
    let moduleItem = <HTMLElement>document.getElementById('module_' + moduleId);

    if (moduleItem !== null) {
      let scrollContainer = <HTMLDivElement>document.getElementsByClassName('container-main')[0];

      moduleItem.scrollIntoView();
    }
  }

  initCollapsableContent() {
    if (isPlatformBrowser(this.platformId)) {
      let moduleContentItems = document.getElementsByClassName('module__content');

      for (let i = 0; i < moduleContentItems.length; i++) {
        let moduleContent = <HTMLElement>moduleContentItems[i];

        if (moduleContent.clientHeight > 300) {
          let articleModuleItem = this.articleItem.modules[i];

          if (articleModuleItem !== undefined && articleModuleItem !== null) {
            articleModuleItem.isCollabsableContent = true;
          }
        }
      }
    }
  }

  onShowSolutionClick() {
    this.showSolution = !this.showSolution;
  }

  onCollapseTextClick(event) {
    let clickedElement = event.target,
      parentContent = clickedElement.closest('.module__table'),
      parentContentToCollapse = clickedElement.closest('.module__table');

    if (parentContentToCollapse === undefined || parentContentToCollapse === null) {
      parentContent = clickedElement.closest('.module__content');
      parentContentToCollapse = clickedElement.closest('.module__content').getElementsByClassName('desc')[0];
    }

    let showMore = <HTMLElement>parentContent.querySelector('.show__more'),
      showLess = <HTMLElement>parentContent.querySelector('.show__less');

    if (!clickedElement.classList.contains('is-collapsed')) {
      clickedElement.classList.add('is-collapsed');
      parentContentToCollapse.classList.add('is-collapsed');

      showMore.style.display = 'none';
      showLess.style.display = 'block';
    } else {
      clickedElement.classList.remove('is-collapsed');
      parentContentToCollapse.classList.remove('is-collapsed');

      showMore.style.display = 'block';
      showLess.style.display = 'none';
    }
  }

  onAccordionHeaderClick(event) {
    let clickedAccordionHeader = event.target,
      accordionParent = clickedAccordionHeader.closest('.accordion'),
      accordionContent = accordionParent.querySelector('.accordion--content');

    if (accordionContent !== undefined && accordionContent !== null) {
      if (accordionContent.classList.contains('is-visible')) {
        clickedAccordionHeader.classList.remove('is-visible');
        accordionContent.classList.remove('is-visible');
      } else {
        clickedAccordionHeader.classList.add('is-visible');
        accordionContent.classList.add('is-visible');
      }
    }
  }

  onStartQuizClick(event) {
    let clickedTarget = event.target,
      moduleIndex = parseInt(clickedTarget.dataset.moduleindex, 10);

    if (this.articleItem !== undefined && this.articleItem !== null) {
      let quizData = this.articleItem.modules[moduleIndex];

      let sharingData = {
        quizPopupOpened: true,
        quizData: quizData
      };
      this.sharingService.emitChange(sharingData);
    }
  }

  onMediaItemClick(event) {
    let clickedImg = event.target,
      clickedModuleIndex = clickedImg.dataset.moduleindex,
      clickedModule = this.articleItem.modules[clickedModuleIndex];

    if (clickedModule !== undefined && clickedModule !== null) {
      this.slidesItems = clickedModule.medias;
      this.galleryPopupActive = true;
    } else {
      this.galleryPopupActive = false;
      if (clickedModuleIndex == 'station') {
        this.slidesItems = this.articleItem.medias;
        this.galleryPopupActive = true;
      }
    }
  }

  closeGalleryPopup() {
    this.slidesItems = [];
    this.galleryPopupActive = false;
  }

  onScrollTopClick() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
