///<reference path="../../../node_modules/@types/node/index.d.ts"/>
import {Component, HostListener, Inject, Input, OnInit, PLATFORM_ID, SimpleChanges} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";

import {FeatureCollection} from './map-box';
import {environment} from "../../environments/environment";

import {SharingService} from "../services/system/sharing.service";
import {SystemService} from "../services/system/system.service";
import {GeolocationService} from "../services/geolocation/geolocation.service";
import {ContentService} from "../services/content/content.service";
import {EchtzeitproxyService} from "../services/echtzeitproxy/echtzeitproxy.service";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'wu-mapbox',
  templateUrl: './map-box.component.html',
  styleUrls: ['./map-box.component.scss']
})
export class MapBoxComponent implements OnInit {

  @Input() mapboxOffsetToCenter = null;

  isMobileDevice = false;
  showNotification = true;

  /// default settings
  mapboxgl: any;
  MapboxLanguage: any;
  //MapboxDirections: any;
  map: any;
  //style = 'mapbox://styles/mapbox/outdoors-v10';
  //style = 'https://cdn.muenchen-p.de/raw/upload/style-json/muenchenapp-swm-karten-komponente-style.json';
  style = 'https://app.muenchen.de/style-json/swm-kartenkomponente-k.json';

  lat = 48.142747563631445;
  lng = 11.461428160108085;

  bounds = [
    [10.592320, 47.422661], // Southwest coordinates
    [13.902400, 49.261500]  // Northeast coordinates
  ];
  mapboxControls;
  mapboxControlsOptions = {
    showCompass: true,
    showZoom: true
  };
  mapboxGeoControls;
  mapboxGeoControlsOptions = {
    positionOptions: {
      enableHighAccuracy: true
    },
    trackUserLocation: true
  };

  mapPointOffset = [-60, 90];

  // data
  source: any;
  markers: any;

  geoLocationAdded = false;

  mvStations = [];
  mvStation = null;

  mapFeatures: Array<any> = [];

  locatePopup: boolean = false;

  // directions api für routing https://github.com/mapbox/mapbox-gl-directions/blob/master/API.md
  // https://www.mapbox.com/help/how-directions-work/
  // https://www.mapbox.com/help/getting-started-directions-api/

  //https://github.com/mapbox/mapbox-gl-directions/blob/master/API.md#mapboxdirections

  // API DOC https://www.mapbox.com/api-documentation/?language=CLI#retrieve-directions

  // styling: https://github.com/mapbox/mapbox-gl-directions/blob/master/src/directions_style.js

  constructor(private sharingService: SharingService,
              private geolocationService: GeolocationService,
              private http: HttpClient,
              private contentService: ContentService,
              private echtzeitProxyServer: EchtzeitproxyService,
              private router: Router,
              @Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      this.mapboxgl = require('mapbox-gl');
      this.MapboxLanguage = require('@mapbox/mapbox-gl-language');

      //this.MapboxDirections = require('@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions');

      this.mapboxgl.accessToken = environment.mapbox.accessToken;
      this.mapboxControls = new this.mapboxgl.NavigationControl(this.mapboxControlsOptions);
      this.mapboxGeoControls = new this.mapboxgl.GeolocateControl(this.mapboxGeoControlsOptions);

      this.map = this.mapboxgl.Map;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if(isPlatformBrowser(this.platformId)) {
      this.checkDeviceType();
    }
    this.removeCurrentInfoIcons();
    this.initInfoIcons();
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange() {
    if(isPlatformBrowser(this.platformId)) {
      this.checkDeviceType();
    }
    this.removeCurrentInfoIcons();
    this.initInfoIcons();
  }

  ngOnInit() {
    if(isPlatformBrowser(this.platformId)) {
      this.checkDeviceType();
    }

    if (isPlatformBrowser(this.platformId)) {
      this.initializeMap();
    }
  }

  initGeoLocationHandlers() {
    // https://github.com/mapbox/mapbox-gl-js/blob/d78b2d1cb162388fd16c4b3607cd2f27bd333a5f/src/ui/control/geolocate_control.js#L120
    //this.map.on('geolocate', this.geoLocationBtnActivated);
    this.mapboxGeoControls.on('trackuserlocationstart', (event: any) => {
    });

    this.mapboxGeoControls.on('trackuserlocationend', (event: any) => {
    });

    this.mapboxGeoControls.on('geolocate', (event: any) => {
      if (event !== undefined && event !== null && event.hasOwnProperty('coords')) {
        this.lat = event.coords.latitude;
        this.lng = event.coords.longitude;

        let userPosition = {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [this.lng, this.lat]
          }
        };

        this.checkIfStationNextToUser(userPosition);
      }
    });
  }

  getDistance(lat1, lon1, lat2, lon2) {
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    } else {
      let radlat1 = Math.PI * lat1 / 180,
        radlat2 = Math.PI * lat2 / 180,
        theta = lon1 - lon2,
        radtheta = Math.PI * theta / 180,
        dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      dist = dist * 1.609344;

      return dist;
    }
  }

  checkIfStationNextToUser(userPosition): void {
    let stationData = this.mapFeatures;
    for (let i = 0; i < stationData.length; i++) {
      let stationItem = stationData[i];

      let distance = this.getDistance(userPosition.geometry.coordinates[1],
        userPosition.geometry.coordinates[0],
        stationItem.geometry.coordinates[1],
        stationItem.geometry.coordinates[0]);

      if (distance <= 0.010) {
        if (!this.locatePopup) {
          setTimeout(() => {
            this.locatePopup = false;
          }, 10000);

          this.locatePopup = true;

          let infoPointElement = <HTMLElement>document.getElementsByClassName('icon--marker')[i];
          if (infoPointElement !== undefined && infoPointElement !== null) {

            if (stationItem.properties.subheadline === null) {
              stationItem.properties.subheadline = 'Hier tippen und mehr Infos erhalten'
            }

            let popup = new this.mapboxgl.Popup({
              closeButton: true,
              closeOnClick: true,
              anchor: 'bottom-left',
              offset: [-100, -40]
            });
            popup.setHTML(`
            <div class="station__popup" data-stationid="${stationItem.properties.stationId}">
              <div class="station__header">
                <p>${stationItem.properties.title}</p>
              </div>
              <div class="station__image"
                style="background: url(https://cdn.muenchen-p.de/image/fetch/c_lfill,w_55,h_55/https://backend.wuermentdecken.de/storage/${stationItem.properties.image}) no-repeat center; background-size: cover;"></div>
              <div class="station__content">
                <i class="icon icon--popup icon--small arrow-right"></i>
                <span>${stationItem.properties.subheadline}</span>
              </div>
            </div>
          `);

            popup.on('open', (e) => {
              let allOpenPopups = document.querySelectorAll('.station__popup'),
                elementForClick = <HTMLElement>allOpenPopups[allOpenPopups.length - 1];

              if (elementForClick === null && (allOpenPopups.length - 1) < 0) {
                elementForClick = <HTMLElement>allOpenPopups[0];
              }

              elementForClick.addEventListener('click', this.onOpenArticlePopupClicked.bind(this));
            });

            popup
              .setLngLat(stationItem.geometry.coordinates)
              .addTo(this.map);

            this.jumpToInfoPoint(i);

            break;
          }
        }
      }
    }
  }

  checkDeviceType() {
    this.isMobileDevice = SystemService.checkDeviceType(this.platformId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes !== undefined && changes !== null && changes.hasOwnProperty('mapboxOffsetToCenter')) {
      if (!this.isMobileDevice && changes.mapboxOffsetToCenter.currentValue !== null) {
        this.mapPointOffset = changes.mapboxOffsetToCenter.currentValue;
      }
    }
  }

  initializeMap() {
    if (!this.isMobileDevice) {
      this.mapPointOffset = [60, 90]
    }

    this.buildMap();
  }

  buildMap() {
    this.map = new this.mapboxgl.Map({
      container: 'map',
      style: this.style,
      //center: [11.575291, 48.131154],
      //pitch: 46.50, // pitch in degrees
      //zoom: 13.50,
      zoom: 11,
      center: [this.lng, this.lat],
      attributionControl: false,
      maxBounds: this.bounds
    });

    this.map.on('style.load', () => {
      const waiting = () => {
        if (!this.map.isStyleLoaded()) {
          setTimeout(waiting, 200);
        } else {
          // Add map controls
          this.map.addControl(this.mapboxControls, 'top-right');

          // Add map geo controls
          this.map.addControl(this.mapboxGeoControls);

          let language = new this.MapboxLanguage();
          this.map.addControl(language);

          if (this.mapboxGeoControls !== undefined) {
            this.initGeoLocationHandlers();
          }
          this.initPathLines();
        }
      };
      waiting();
    });

    this.map.on('click', (event) => {
      this.mvStation = null;
    })

    this.map.on('zoomend', (event) => {
      let rZoomLevel = Math.round(this.map.getZoom() * 100) / 100;
      let elems = document.getElementsByClassName('icon--marker');

      if (rZoomLevel > 12.30) {
        for (let i = 0; i != elems.length; ++i) {
          let ele = <HTMLElement>elems[i];
          ele.style.visibility = "visible";
        }
      } else {
        for (let i = 0; i != elems.length; ++i) {
          let ele = <HTMLElement>elems[i];
          ele.style.visibility = "hidden";
        }
      }
    })

    this.map.on('moveend', (event) => {
      this.handleMvStations();
    });
  }

  handleMvDepartues(station, stationName) {
    this.echtzeitProxyServer.getMvDepartures(station).subscribe((result: any) => {
      let res = result.result;

      for (let i = 0; i < res.length; i++) {
        res[i].time = this.transform(new Date(res[i].departureTime));
      }

      this.mvStation = res;
      this.mvStation['name'] = stationName;
    });
  }

  transform(time: Date): string {
    let seconds = Math.round((time.getTime() - (new Date()).getTime()) / 1000);
    if (seconds < 60) {
      //return seconds + "s";
      return "-";
    } else if (seconds < 3600) {
      return Math.floor(seconds / 60).toString() + " Min.";
    } else {
      let h = Math.floor(seconds / 3600);
      return h.toString() + ":" + Math.floor((seconds - (h * 3600)) / 60) + " Std.";
    }
  }

  handleMvStations() {
    if (this.map.getZoom() > 13) {
      this.echtzeitProxyServer.getCategoryPlacesForBoundingBox('mv.public', this.map.getBounds(), this.map.getZoom()).subscribe((result: any) => {
        let res = result.results;

        if (this.mvStations.length > 0) {
          for (let i = 0; i < this.mvStations.length; i++) {
            this.mvStations[i].remove();
          }

          this.mvStations = [];
        }

        for (let i = 0; i < res.length; i++) {
          if (res[i].location.lng != null || res[i].location.lat != null) {
            let catCount = 0;

            for (let a = 0; a < res[i].categories.length; a++) {
              if (res[i].categories[a].id == 'mv.public.sbahn' || res[i].categories[a].id == 'mv.public.ubahn' || res[i].categories[a].id == 'mv.public.tram' || res[i].categories[a].id == 'mv.public.bus') {
                catCount = catCount + 1;
              }
            }

            let el = document.createElement('div');
            el.className = 'track-marker';
            el.style.color = '#F14D10';
            el.style.backgroundImage = "url('" + res[i].categoryIconStatic + "')";
            el.style.backgroundSize = "cover";
            el.style.fontSize = '13pt';
            el.style.display = 'block';
            el.style.position = 'absolute';
            el.className = 'marker-' + catCount;

            el.addEventListener('click', (event) => {
                this.handleMvDepartues(res[i].id, res[i].title);
                this.map.jumpTo({
                  center: [res[i].location.lng, res[i].location.lat],
                  zoom: 17
                });
              }
            );
            this.mvStations.push(new this.mapboxgl.Marker({
              element: el,
              offset: [0, 0]
            }).setLngLat([res[i].location.lng, res[i].location.lat])
              .addTo(this.map));

          }
        }
      });
    } else {
      this.mvStation = null;
      if (this.mvStations.length > 0) {
        for (let i = 0; i < this.mvStations.length; i++) {
          this.mvStations[i].remove();
        }

        this.mvStations = [];
      }
    }
  }

  initPathLines() {
    if (this.contentService.contentAll !== undefined &&
      this.contentService.contentAll.stations !== undefined &&
      this.contentService.contentAll.stations.length > 0) {
      this.initMapPoints(this.contentService.contentAll.stations);
      this.initMapTrackPoints();
    } else {
      this.contentService.getStationGeoAll().subscribe((result: any) => {
        this.initMapPoints(result);
      });
    }
  }

  initMapTrackPoints() {
    let trackPoints = this.contentService.contentAll.tracks;

    for (let i = 0; i < trackPoints.length; i++) {
      if (trackPoints[i].lng != null || trackPoints[i].lat != null) {
        let el = document.createElement('div');
        el.className = 'track-marker';
        el.style.color = '#F14D10';
        el.style.fontSize = '13pt';
        el.style.width = '120px';
        el.style.position = 'absolute';
        el.innerText = trackPoints[i].name;

        el.addEventListener('click', (event) => {
            this.map.jumpTo({
              center: [trackPoints[i].lng, trackPoints[i].lat],
              zoom: 14
            });
          }
        );
        new this.mapboxgl.Marker({
          element: el,
          offset: [35, 0]
        }).setLngLat([trackPoints[i].lng, trackPoints[i].lat])
          .addTo(this.map);

      }
    }
  }

  initMapPoints(data) {
    let stationsAll = data;

    this.mapFeatures = [];
    for (let i = 0; i < stationsAll.length; i++) {
      let stationItem = stationsAll[i];

      if (stationItem.hasOwnProperty('positions')) {
        for (let j = 0; j < stationItem.positions.length; j++) {
          if (stationItem.positions[j].lng != null) {
            let positionItem = stationItem.positions[j];
            let newFeature = {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [11.457066, 48.195707]
              },
              properties: {
                title: '',
                description: '',
                subheadline: '',
                image: '',
                stationId: 1
              }
            };
            newFeature.geometry.coordinates = [positionItem.lng, positionItem.lat];
            newFeature.properties.title = stationItem.name;
            newFeature.properties.subheadline = stationItem.subheadline;
            newFeature.properties.image = stationItem.medias[0].filename;
            newFeature.properties.stationId = stationItem.id;

            this.mapFeatures.push(newFeature);
          }
        }
      }
    }

    this.map.addLayer({
      "id": "path-middle",
      "type": "line",
      "source": {
        "type": "geojson",
        "data": {
          "type": "FeatureCollection",
          "name": "tracks",
          "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
          "features": [
            {
              "type": "Feature",
              "properties": {},
              "geometry": {"type": "MultiLineString", "coordinates": [[[11.457078300023568, 48.163642800075195], [11.456960600351465, 48.163621800006126], [11.45694849997335, 48.163622300029203], [11.456934600051795, 48.163625599641762], [11.456921200153317, 48.163632599964558]]]}
            },
            {
              "type": "Feature",
              "properties": {},
              "geometry": {"type": "MultiLineString", "coordinates": [[[11.457885199642305, 48.154437100391931], [11.457945500084804, 48.154403499921614], [11.458198900259106, 48.154417300018451], [11.458253099700812, 48.154407400281343], [11.458469800341049, 48.154313199894318]]]}
            },
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.457234999695515, 48.163674100079731], [11.457362299630461, 48.163702300121088]]]}},
            {
              "type": "Feature",
              "properties": {},
              "geometry": {"type": "MultiLineString", "coordinates": [[[11.458177199618092, 48.155303500056561], [11.458167399705758, 48.155144499918777], [11.458160299558244, 48.155020999619467], [11.458008000269047, 48.154722400418507], [11.457885199642305, 48.154437100391931]]]}
            },
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.458206000406619, 48.155333999664379], [11.458177199618092, 48.155303500056561]]]}},
            {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "MultiLineString",
                "coordinates": [[[11.457621299883158, 48.162095399583052], [11.457522000340077, 48.162172400435963], [11.457339500017952, 48.162300900066555], [11.457110900447731, 48.162348699932636], [11.456905099590699, 48.16239330001082], [11.456796900356721, 48.162425799710945], [11.456547700016415, 48.162510600383939], [11.456455100422602, 48.162529299987284], [11.456314100215593, 48.162617799571819], [11.456201300049997, 48.162742799940304], [11.456137100147089, 48.162848099760026], [11.456093199741417, 48.16299860040516], [11.456117500322421, 48.163286100173423], [11.456174899552025, 48.163458799683895], [11.456301499814401, 48.163564700250788], [11.45643809963866, 48.163626800236671]]]
              }
            },
            {
              "type": "Feature",
              "properties": {},
              "geometry": {"type": "MultiLineString", "coordinates": [[[11.458432800433457, 48.164061199764603], [11.458380500359851, 48.164115300280912], [11.458366200239993, 48.164167399805706], [11.458530000059511, 48.164428099777012], [11.458582499782608, 48.164573600191545]]]}
            },
            {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "MultiLineString",
                "coordinates": [[[11.457362299630461, 48.163702300121088], [11.457587800136935, 48.163744400084056], [11.45781079962876, 48.16379029968266], [11.45819420040209, 48.1639195997098], [11.458339199894226, 48.163992100355188], [11.458432800433457, 48.164061199764603]]]
              }
            },
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.457078300023568, 48.163642800075195], [11.457234999695515, 48.163674100079731]]]}},
            {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "MultiLineString",
                "coordinates": [[[11.458698999759065, 48.15650100042086], [11.458597999598055, 48.156460600176615], [11.458531299579874, 48.156215900043833], [11.458327999737435, 48.155845400045223], [11.458226200079139, 48.155535499963378], [11.458206000406619, 48.155333999664379]]]
              }
            },
            {
              "type": "Feature", "properties": {}, "geometry": {
                "type": "MultiLineString",
                "coordinates": [[[11.457621299883158, 48.162095399583052], [11.457626399938476, 48.162023899883138], [11.457744200334616, 48.161819900368187], [11.457936300020265, 48.161628600179824], [11.458140599908802, 48.161493100226437], [11.45826689979765, 48.161361899931762], [11.45829430034172, 48.161185700259921], [11.458109999576891, 48.16050540000424], [11.45804860016284, 48.160276599885208], [11.458049700033655, 48.160045400374997], [11.458240400374223, 48.159357099750423], [11.458340500313909, 48.159006999973144], [11.458355400281562, 48.158879700038199], [11.458332999968036, 48.158684100191124], [11.458315699709829, 48.158544400403798], [11.458346800064817, 48.158354700109385], [11.458439000359647, 48.158073400267256], [11.458469599792238, 48.157849700203542], [11.458336400304688, 48.157538700250882], [11.458296199709878, 48.157379799937871], [11.45831409981588, 48.157243499587878], [11.458517100184054, 48.156842400156677], [11.45862159960717, 48.156727199700583], [11.458678399888299, 48.156700800101873]]]
              }
            },
            {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "MultiLineString",
                "coordinates": [[[11.458251700355731, 48.153702199596864], [11.458200699802489, 48.153734499647499], [11.458179400259155, 48.153782400237617], [11.458206600254471, 48.153838999969935], [11.458285600300258, 48.15393459970204], [11.458461999621647, 48.154183800042404], [11.458469800341049, 48.154313199894318]]]
              }
            },
            {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "MultiLineString",
                "coordinates": [[[11.456921200153317, 48.163632599964558], [11.456871300370267, 48.163673400407163], [11.45684389982614, 48.163687900176512], [11.456803999604972, 48.163701800098067], [11.456763999558973, 48.16370869969677], [11.45668860039865, 48.163708500047278], [11.456577400126946, 48.163675199950546], [11.45643809963866, 48.163626800236671]]]
              }
            },
            {
              "type": "Feature",
              "properties": {},
              "geometry": {"type": "MultiLineString", "coordinates": [[[11.458530500082588, 48.167397900392814], [11.45853209997648, 48.167312099673723], [11.458600800086913, 48.16704879976237], [11.458575000336054, 48.166682399772981], [11.458556899681241, 48.166363500175464]]]}
            },
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.458704599837461, 48.167365299968651], [11.458780699569672, 48.167462299945214], [11.459033499896179, 48.16834880035691], [11.458908500427015, 48.168544400203984]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.458582499782608, 48.164573600191545], [11.458653899657804, 48.164753899872608]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.458653899657804, 48.164753899872608], [11.458683699593109, 48.164831499674051], [11.458704199639158, 48.164910600443932]]]}},
            {
              "type": "Feature",
              "properties": {},
              "geometry": {"type": "MultiLineString", "coordinates": [[[11.458447999875318, 48.165524900353944], [11.458343499552939, 48.165553899892643], [11.457904300343216, 48.165599099818678], [11.457899700310975, 48.165735599818106], [11.457897600394006, 48.165796000085379]]]}
            },
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.458682999920541, 48.165465099934465], [11.458608400257503, 48.165482099819087]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.458556899681241, 48.166363500175464], [11.458528599815054, 48.16622540028203]]]}},
            {
              "type": "Feature",
              "properties": {},
              "geometry": {"type": "MultiLineString", "coordinates": [[[11.458528599815054, 48.16622540028203], [11.458421799926157, 48.166075200010539], [11.458117099724404, 48.165895500177271], [11.458021499992299, 48.165836299605587], [11.457897600394006, 48.165796000085379]]]}
            },
            {
              "type": "Feature", "properties": {}, "geometry": {
                "type": "MultiLineString",
                "coordinates": [[[11.462831399849279, 48.173765499778028], [11.462783900356783, 48.173340499964183], [11.462693200131127, 48.173122399978809], [11.46263699969785, 48.172938699961094], [11.462591500297549, 48.172710399865139], [11.462608799656437, 48.172481999944409], [11.462354299611263, 48.172154000107071], [11.462226399828523, 48.171902799674513], [11.462100500138035, 48.171666199735398], [11.462076300281069, 48.171548300413804], [11.461970700087761, 48.171385399916346], [11.461706999978105, 48.171229499741685], [11.461489700389393, 48.171186499557336], [11.461329800030285, 48.171138000018743], [11.460452800056714, 48.170535099914957], [11.460391499568118, 48.170500500297919], [11.460144900067121, 48.170361400358388], [11.4598077001653, 48.170183699717995], [11.459544300429172, 48.170077200202684], [11.459265700351864, 48.1698160002083], [11.45904200028815, 48.169697000116514], [11.458908799901224, 48.169491999656088], [11.458805699823245, 48.169449299845382]]]
              }
            },
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.458608400257503, 48.165482099819087], [11.458447999875318, 48.165524900353944]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.458805699823245, 48.169449299845382], [11.458879400164221, 48.169142499726661], [11.458897200445506, 48.168951800285413]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.458908500427015, 48.168544400203984], [11.458955800270019, 48.168671100291078], [11.45897280015464, 48.16871680024019], [11.458897200445506, 48.168951800285413]]]}},
            {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "MultiLineString",
                "coordinates": [[[11.460892600013551, 48.176486500251826], [11.460904800216383, 48.176372200017056], [11.461015999588767, 48.176346500090972], [11.461097800123412, 48.176317399828179], [11.461186699906307, 48.176275799888288], [11.461389599550444, 48.176053000045954], [11.461561299914081, 48.175915599825146], [11.461828799659429, 48.175797900153043], [11.462311300326292, 48.175645500139751], [11.46253119985505, 48.175440499679382], [11.462737200361573, 48.175251699606292], [11.462934999950335, 48.175126499588316], [11.463175100050876, 48.17481289969561], [11.463209800392008, 48.17472249984354], [11.463109800277095, 48.174340400389212], [11.462953499904131, 48.174123500099483], [11.462824799724785, 48.173964400137038], [11.462805799747912, 48.173855100132812], [11.462831399849279, 48.173765499778028]]]
              }
            },
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.458678399888299, 48.156700800101873], [11.458668699800683, 48.156585399996288], [11.458698999759065, 48.15650100042086]]]}},
            {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "MultiLineString",
                "coordinates": [[[11.458704199639158, 48.164910600443932], [11.458570199754945, 48.164971599659623], [11.458514299695196, 48.165047399917626], [11.458517600207131, 48.165137399571393], [11.458573899565863, 48.165291900400916], [11.458682999920541, 48.165465099934465]]]
              }
            },
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.458530500082588, 48.167397900392814], [11.458704599837461, 48.167365299968651]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.4631571001201, 48.173717400437738], [11.462831399849279, 48.173765499778028]]]}}
          ]
        }
        ,
      },
      "layout": {
        "line-join": "round",
        "line-cap": "round"
      },
      "paint": {
        "line-color": "#F14D10",
        "line-width": 4
      }
    });
    this.map.addLayer({
      "id": "path-south",
      "type": "line",
      "source": {
        "type": "geojson",
        "data": {
          "type": "FeatureCollection",
          "name": "tracks",
          "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
          "features": [
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.4498974, 48.1355151], [11.4499234, 48.1355869]]]}},
            {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "MultiLineString",
                "coordinates": [[[11.4524473, 48.1383162], [11.4524329, 48.1377954], [11.4524161, 48.1376927], [11.4522412, 48.1375048], [11.4520404, 48.1374229], [11.4518314, 48.1372373], [11.4515763, 48.1370903], [11.4513075, 48.1370171], [11.4511122, 48.1367709], [11.4505196, 48.1363365], [11.4499431, 48.1359686], [11.4499057, 48.1357936], [11.4499304, 48.1356926], [11.4499234, 48.1355869]]]
              }
            },
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.4487469, 48.1341668], [11.4489152, 48.1344798], [11.449091, 48.1346625], [11.4492948, 48.1348388], [11.449506, 48.1349863], [11.4495808, 48.1350523], [11.449624, 48.1351009]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.449624, 48.1351009], [11.4496435, 48.13512]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.4496435, 48.13512], [11.4498069, 48.1352593], [11.4498412, 48.135352], [11.4500027, 48.1354407]]]}},
            {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "MultiLineString",
                "coordinates": [[[11.453483, 48.1405285], [11.4532185, 48.1405848], [11.4529159, 48.1405092], [11.4527079, 48.1402114], [11.4525111, 48.1397644], [11.4524101, 48.139455], [11.452117, 48.1389088], [11.4520655, 48.1387312], [11.4521565, 48.1384487], [11.4524473, 48.1383162]]]
              }
            },
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.4536666, 48.140663], [11.4537254, 48.1407591], [11.4537218, 48.1410915], [11.4538258, 48.1416829], [11.454096, 48.143195]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.4564867, 48.147182], [11.4564905, 48.147209], [11.4565388, 48.147414], [11.4565698, 48.1474228]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.4564738, 48.1464705], [11.4562187, 48.1464915]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.4562187, 48.1464915], [11.4560966, 48.1464968]]]}},
            {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "MultiLineString",
                "coordinates": [[[11.456604, 48.1464483], [11.4565883, 48.1464185], [11.4565723, 48.1463873], [11.4564503, 48.1462808], [11.4562506, 48.1460681], [11.4560806, 48.1458647], [11.4559725, 48.1458417], [11.4557842, 48.1456174], [11.4557504, 48.1455443], [11.4555664, 48.1451013], [11.4554674, 48.1449739], [11.455277, 48.1448141], [11.4551224, 48.1446185], [11.4548711, 48.1442445], [11.4547039, 48.1439749]]]
              }
            },
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.456604, 48.1464483], [11.4564738, 48.1464705]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.454096, 48.143195], [11.4543084, 48.1431845]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.4562046, 48.1466034], [11.4563447, 48.1466788], [11.4564119, 48.1467896]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.4565698, 48.1474228], [11.4566091, 48.1476501], [11.4565956, 48.1478251], [11.4564511, 48.148234]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.4564119, 48.1467896], [11.456448, 48.1469521], [11.4564867, 48.147182]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.4560966, 48.1464968], [11.4561225, 48.1465487], [11.4562046, 48.1466034]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.4571497, 48.1487895], [11.4566765, 48.1488247], [11.4565311, 48.1488714]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.4580668, 48.14967], [11.4576751, 48.1492315], [11.4574992, 48.1491345]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.4571497, 48.1487895], [11.4571783, 48.1488518], [11.4574455, 48.1490921], [11.4574992, 48.1491345]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.4564669, 48.1482396], [11.4565653, 48.1482706]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.4564511, 48.148234], [11.4564669, 48.1482396]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.4500027, 48.1354407], [11.4498974, 48.1355151]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.4536666, 48.140663], [11.453483, 48.1405285]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.4547039, 48.1439749], [11.4544193, 48.1434809], [11.4543084, 48.1431845]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.4565653, 48.1482706], [11.4565872, 48.1482752]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.4565872, 48.1482752], [11.4564065, 48.1486114], [11.4565311, 48.1488714]]]}}
          ]
        }
      },
      "layout": {
        "line-join": "round",
        "line-cap": "round"
      },
      "paint": {
        "line-color": "#F14D10",
        "line-width": 4
      }
    });
    this.map.addLayer({
      "id": "path-north",
      "type": "line",
      "source": {
        "type": "geojson",
        "data": {
          "type": "FeatureCollection",
          "name": "tracks",
          "crs": {"type": "name", "properties": {"name": "urn:ogc:def:crs:OGC:1.3:CRS84"}},
          "features": [
            {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "MultiLineString",
                "coordinates": [[[11.456205499883936, 48.19618269987177], [11.456288599939001, 48.196095999830561], [11.456380200385979, 48.195984799558914], [11.456565899596626, 48.195792299674963], [11.456590600375932, 48.195737000362328], [11.456603600076107, 48.195599399592652], [11.456648700177425, 48.195453400054419], [11.456846700314998, 48.195130600097173], [11.45691450020405, 48.195033399571798], [11.45699040028677, 48.194920100282445]]]
              }
            },
            {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "MultiLineString",
                "coordinates": [[[11.458096999876716, 48.192448599623219], [11.458151499692008, 48.192418000190628], [11.45824710032349, 48.192329999729736], [11.458223899613301, 48.192209200094567], [11.458213800226758, 48.192151999615135], [11.458220299627158, 48.192094800035022], [11.458208399797854, 48.191924499915785], [11.458208200148363, 48.191820399791652], [11.458230399913077, 48.191478900231118], [11.458383200124615, 48.191155200052492], [11.458417000244424, 48.191071599974407], [11.458459000382618, 48.191001499619574], [11.45856819966275, 48.190816599906213], [11.458616999574929, 48.190741400395382], [11.458818400049154, 48.190523400234781], [11.459017599882372, 48.190306800318638], [11.459101399609949, 48.190177600116215], [11.459133300361543, 48.190023600209031]]]
              }
            },
            {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "MultiLineString",
                "coordinates": [[[11.456074299589318, 48.199216400016894], [11.456073400267258, 48.199172100312239], [11.455999699926281, 48.198580699839454], [11.456116299727512, 48.198125499891944], [11.456005999677188, 48.197875099856049], [11.456002000392061, 48.19786610034032], [11.455968599571236, 48.197782299713424], [11.455969300143124, 48.197689499570799], [11.455982600216885, 48.197583599903282], [11.45598200036909, 48.19751839995422], [11.455951700410708, 48.197460800175861], [11.455912900060355, 48.197424800314309], [11.455834900060665, 48.197370000125431], [11.455757499908771, 48.197322899931919]]]
              }
            },
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.45699040028677, 48.194920100282445], [11.457206299631082, 48.194976899664255]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.456002000392061, 48.19786610034032], [11.45638249995244, 48.197669600271865]]]}},
            {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "MultiLineString",
                "coordinates": [[[11.458096999876716, 48.192448599623219], [11.458112399867389, 48.192613500212929], [11.458061800411826, 48.192813399718659], [11.458020199572616, 48.192902800423951], [11.45800099994625, 48.192944000165539], [11.457908500177211, 48.193080000141947], [11.457748800366915, 48.193251900155133], [11.457609200404306, 48.193408099804003], [11.457284900377886, 48.193718099710566], [11.457164699691191, 48.193841600009875], [11.45711389968676, 48.193978699857155], [11.457079700268025, 48.194076500230381], [11.457091400447837, 48.194181600400611], [11.457170500318398, 48.194574700362182], [11.457202900193749, 48.194735099845047], [11.457221400147546, 48.194892000065806], [11.457206299631082, 48.194976899664255]]]
              }
            },
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.454607900134533, 48.204115900228658], [11.455459600380323, 48.204214799573379], [11.455788100240682, 48.204218500283616], [11.456040500368829, 48.204194500076198]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.456040500368829, 48.204194500076198], [11.456125700340863, 48.204160199933312]]]}},
            {
              "type": "Feature",
              "properties": {},
              "geometry": {"type": "MultiLineString", "coordinates": [[[11.455044199929944, 48.201374000409828], [11.454995400017765, 48.201475399869878], [11.454968100197732, 48.201591200173823], [11.45478919986175, 48.202963800339148], [11.454607900134533, 48.204115900228658]]]}
            },
            {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "MultiLineString",
                "coordinates": [[[11.457111799769791, 48.202645700238918], [11.456984900033149, 48.202612099768658], [11.456804299978501, 48.202543500382262], [11.456666700108144, 48.2025462999718], [11.456479300279511, 48.202579500243814], [11.456389799749502, 48.202713900326273], [11.456339999791226, 48.202919899933477], [11.456375499629644, 48.203054899863787], [11.456479300279511, 48.203222000195183], [11.456520599845874, 48.203374400208475], [11.456528200016464, 48.203490300337137], [11.456458200385669, 48.203610199750983], [11.456362800303054, 48.203701900022736], [11.456242899989888, 48.203811000377414], [11.456139100239341, 48.203905500238704], [11.456127300234755, 48.203978699657341], [11.456139100239341, 48.204049499684743], [11.456166599708865, 48.204126899836638]]]
              }
            },
            {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "MultiLineString",
                "coordinates": [[[11.455499499702171, 48.200717499812811], [11.455421999725559, 48.200667299656232], [11.455425599711702, 48.200501399919744], [11.455418399739415, 48.200364300072465], [11.455431099965324, 48.200234400197473], [11.455450900338803, 48.200127200110273], [11.455477999610025, 48.20004080044265], [11.455598899969289, 48.199769000139327], [11.455833399991491, 48.199297599804424], [11.45593559984809, 48.199318400224058]]]
              }
            },
            {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "MultiLineString",
                "coordinates": [[[11.459853300289637, 48.188590400126941], [11.459838299597891, 48.188836499604804], [11.45971640009185, 48.18918710030448], [11.459630100249001, 48.189390300322145], [11.459527900392345, 48.189604099749431], [11.459436100295818, 48.189743800436077], [11.459371699844098, 48.189817200403525], [11.45922189977091, 48.18995900010782], [11.459133300361543, 48.190023600209031]]]
              }
            },
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.456529500436147, 48.197529299737425], [11.45638249995244, 48.197669600271865]]]}},
            {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "MultiLineString",
                "coordinates": [[[11.455757499908771, 48.197322899931919], [11.455811399876268, 48.197271099881391], [11.455921500277043, 48.197177699890972], [11.456001399644947, 48.196959699730428], [11.456034400267413, 48.196737499735889], [11.456086099593847, 48.196441499575599], [11.456205499883936, 48.19618269987177]]]
              }
            },
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.456074299589318, 48.199216400016894], [11.45599650013844, 48.199215499795514]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.45599650013844, 48.199215499795514], [11.45593559984809, 48.199318400224058]]]}},
            {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "MultiLineString",
                "coordinates": [[[11.455499499702171, 48.200717499812811], [11.455476700089662, 48.200828999558723], [11.455424700389642, 48.200961600097799], [11.455379999587365, 48.201050499880694], [11.45527569981374, 48.201186599681876], [11.455161399578969, 48.201288800437851], [11.455044199929944, 48.201374000409828]]]
              }
            },
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.456125700340863, 48.204160199933312], [11.456141499630576, 48.204147300057912], [11.456166599708865, 48.204126899836638]]]}},
            {"type": "Feature", "properties": {}, "geometry": {"type": "MultiLineString", "coordinates": [[[11.456479300279511, 48.202579500243814], [11.45631658324379, 48.202382925531651], [11.456088155444093, 48.202300834515938], [11.455891849629211, 48.202282988369291]]]}}
          ]
        }

      },
      "layout": {
        "line-join": "round",
        "line-cap": "round"
      },
      "paint": {
        "line-color": "#F14D10",
        "line-width": 4
      }
    });


    this.initInfoIcons();
    this.initWuermrangerIcons();
  }

  initInfoIcons() {
    let geojson = {
      type: 'FeatureCollection',
      features: this.mapFeatures
    };

    // add markers to map
    geojson.features.forEach((marker, index) => {

      // create a HTML element for each feature
      let el = document.createElement('div');
      el.className = 'icon icon--marker icon--small info';
      el.style.visibility = 'hidden';
      el.dataset.markerindex = index.toString();

      if (marker.properties.subheadline === null) {
        marker.properties.subheadline = 'Hier tippen und mehr Infos erhalten'
      }

      let popup = new this.mapboxgl.Popup({
        closeButton: true,
        closeOnClick: true,
        anchor: 'bottom-left',
        offset: [-100, -40]
      });
      popup.setHTML(`
				<div class="station__popup" data-stationid="${marker.properties.stationId}">
					<div class="station__header">
						<p>${marker.properties.title}</p>
					</div>
					<div class="station__image"
						style="background: url(https://backend.wuermentdecken.de/storage/${marker.properties.image}) no-repeat center; background-size: cover;"></div>
					<div class="station__content">
						<i class="icon icon--popup icon--small arrow-right"></i>
						<span>${marker.properties.subheadline}</span>
					</div>
				</div>
				`);

      el.addEventListener('click', (event) => {
          let clickedMarker = <HTMLElement>event.target,
            markerIndex = clickedMarker.dataset.markerindex;
          this.jumpToInfoPoint(markerIndex);
        }
      );

      popup.on('open', (e) => {
        let allOpenPopups = document.querySelectorAll('.station__popup'),
          elementForClick = <HTMLElement>allOpenPopups[allOpenPopups.length - 1];

        elementForClick.addEventListener('click', this.onOpenArticlePopupClicked.bind(this));
      });

      // make a marker for each feature and add to the map
      new this.mapboxgl.Marker(el)
        .setLngLat(marker.geometry.coordinates)
        .setPopup(popup) // add popups
        .addTo(this.map);

    });
  }

  initWuermrangerIcons() {
    let geojson = {
      type: 'FeatureCollection',
      features: this.contentService.contentAll.wuermranger
    };

    geojson.features.forEach((marker, index) => {

      // create a HTML element for each feature
      let el = document.createElement('div');
      el.className = 'icon icon--marker icon--small info-wr';
      el.style.visibility = 'hidden';
      el.style.backgroundColor = 'white';
      el.style.borderRadius = '30px';
      el.style.width = '20px';
      el.style.height = '20px';
      el.style.left = '10px';
      el.dataset.markerindex = "wr-" + index.toString();

      let popup = new this.mapboxgl.Popup({
        closeButton: true,
        closeOnClick: true,
        anchor: 'bottom-left',
        offset: [-100, -40]
      });
      popup.setHTML(`
				<div class="station__popup" data-stationid="wr-${index}">
					<div class="station__header">
						<p>${marker.name}</p>
					</div>
					<div class="station__content" style="width: 100%;">
						<span style="left: 10px">Hier ist eine Station der Würmranger. Mehr Informationen gibt es unter "Weiterführende Informationen"</span>
					</div>
				</div>
				`);

      el.addEventListener('click', (event) => {
          let clickedMarker = <HTMLElement>event.target,
            markerIndex = clickedMarker.dataset.markerindex;
          let clickedPlaceCoordinates = [marker.lng, marker.lat];

          this.map.easeTo({
            center: clickedPlaceCoordinates,
            offset: this.mapPointOffset
          });
        }
      );

      popup.on('open', (e) => {
        let allOpenPopups = document.querySelectorAll('.station__popup'),
          elementForClick = <HTMLElement>allOpenPopups[allOpenPopups.length - 1];

        elementForClick.addEventListener('click', this.onOpenArticlePopupClicked.bind(this));
      });

      // make a marker for each feature and add to the map
      new this.mapboxgl.Marker(el)
        .setLngLat([marker.lng, marker.lat])
        .setPopup(popup) // add popups
        .addTo(this.map);

    });
  }

  onOpenArticlePopupClicked(event) {
    let clickedElement = event.target,
      articleId = parseInt(clickedElement.dataset.stationid, 10);

    if (!clickedElement.classList.contains('station__popup')) {
      clickedElement = clickedElement.closest('.station__popup');
      articleId = parseInt(clickedElement.dataset.stationid, 10);
    }

    let sharingData = {
      articlePopupOpened: true,
      articleId: articleId
    };
    this.sharingService.emitChange(sharingData);
  }

  removeCurrentInfoIcons() {
    let currentMarkers = document.getElementsByClassName('icon--marker');

    if (currentMarkers !== undefined && currentMarkers !== null) {
      while (currentMarkers[0]) {
        currentMarkers[0].parentNode.removeChild(currentMarkers[0]);
      }
    }
  }

  jumpToInfoPoint(index = null) {
    let clickedPlaceCoordinates = [this.mapFeatures[index].geometry.coordinates[0], this.mapFeatures[index].geometry.coordinates[1]];

    this.map.easeTo({
      center: clickedPlaceCoordinates,
      offset: this.mapPointOffset
    });
  }

  closeNotification() {
    this.showNotification = false;
  }

  onMvgEntryClose() {
    this.mvStation = null;
  }
}
