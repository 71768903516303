import {Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID} from '@angular/core';
import {SystemService} from "../services/system/system.service";
import {SharingService} from "../services/system/sharing.service";

@Component({
  selector: 'wu-mainnav',
  templateUrl: './mainnav.component.html',
  styleUrls: ['./mainnav.component.scss']
})
export class MainnavComponent implements OnInit {

  @Input() viewmode = 2;
  @Output() menuLinkClick = new EventEmitter();

  isMobileDevice = false;

  constructor(private sharingService: SharingService,
              @Inject(PLATFORM_ID) private platformId: Object) {
  }

  ngOnInit() {
    this.isMobileDevice = SystemService.checkDeviceType(this.platformId);
  }

  onMenuLinkClicked() {
    this.menuLinkClick.emit();
    let sharingData = {
      articlePopupClosed: true,
      quizPopupClosed: true
    };
    this.sharingService.emitChange(sharingData);
  }

}
