import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {SharingService} from "../services/system/sharing.service";

@Component({
  selector: 'wu-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() mainMenuState = false;
  @Input() viewmode = 0;
  @Output() mainMenuOpened = new EventEmitter();

  mainMenuActive = false;

  constructor(private sharingService: SharingService,
              private router: Router) {
  }

  ngOnInit() {
  }

  onLogoClick() {
    this.mainMenuActive = !this.mainMenuState;

    let sharingData = {
      articlePopupClosed: false
    };
    this.sharingService.emitChange(sharingData);

    this.router.navigate(['']);
  }

  onMainMenuOpened() {
    this.mainMenuActive = !this.mainMenuState;
    this.mainMenuOpened.emit(this.mainMenuActive);
  }

}
