import {Component} from "@angular/core";
import {Title} from "@angular/platform-browser";

@Component({
    selector: 'wu-dateprotection',
    templateUrl: './dateprotection.component.html'
})

export class DateprotectionComponent {
    constructor(private titleService: Title) {
    }

    ngOnInit()
    {
        this.setTitle('Datenschutzerklärung - Würmlehrpfad');
    }
    setTitle( newTitle: string) {
        this.titleService.setTitle( newTitle );
    }
}
