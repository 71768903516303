import {Component, HostListener, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {SystemService} from "../../services/system/system.service";
import {Title} from "@angular/platform-browser";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'wu-map-box-pageview',
  templateUrl: './map-box-pageview.component.html',
  styleUrls: ['./map-box-pageview.component.scss']
})
export class MapBoxPageviewComponent implements OnInit {

  isMobileDevice: boolean = false;
  isViewMode: number = 0;

  constructor(private titleService: Title,
              @Inject(PLATFORM_ID) private platformId: Object) {
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if(isPlatformBrowser(this.platformId)) {
      this.checkDeviceType();
    }
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange() {
    if(isPlatformBrowser(this.platformId)) {
      this.checkDeviceType();
    }
  }

  checkDeviceType() {
    this.isMobileDevice = SystemService.checkDeviceType(this.platformId);

    let isDesktop = window.matchMedia("(min-width: 1200px)").matches,
      isHybrid = window.matchMedia("(max-width: 1200px)").matches,
      isMobile = window.matchMedia("(max-width: 600px)").matches;

    if (isDesktop) {
      this.isViewMode = 0;
    }

    if (isHybrid) {
      this.isViewMode = 1;
    }

    if (isMobile) {
      this.isViewMode = 2;
    }
  }

  ngOnInit() {
    this.setTitle('Karte - Würmlehrpfad');
  }

  setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

}
