import {Component, HostListener, Inject, PLATFORM_ID} from '@angular/core';
import {SystemService} from "./services/system/system.service";
import {SharingService} from "./services/system/sharing.service";
import {GeolocationService} from "./services/geolocation/geolocation.service";
import {ContentService} from "./services/content/content.service";
import {Title} from "@angular/platform-browser";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-root',
  providers: [
    SystemService,
    SharingService,
    GeolocationService,
    ContentService
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  mainnavActive: boolean = false;

  mapActive: boolean = false;

  isMobileDevice: boolean = false;

  isViewMode: number = 0; // 0 = desktop, 1 = hybrid, 2 = mobile

  articlePopupActive: boolean = false;

  activeArticleId: number = 0;

  quizPopupActive: boolean = false;
  quizData: any;

  constructor(private sharingService: SharingService,
              private contentService: ContentService,
              @Inject(PLATFORM_ID) private platformId: Object,
              private titleService: Title) {

  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if(isPlatformBrowser(this.platformId)) {
      this.checkDeviceType();
    }
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange() {
    if(isPlatformBrowser(this.platformId)) {
      this.checkDeviceType();
    }
  }

  checkDeviceType() {
    this.isMobileDevice = SystemService.checkDeviceType(this.platformId);

    let isDesktop = window.matchMedia("(min-width: 1200px)").matches,
      isHybrid = window.matchMedia("(max-width: 1200px)").matches,
      isMobile = window.matchMedia("(max-width: 900px)").matches;

    if (isDesktop) {
      this.isViewMode = 0;
    }

    if (isHybrid) {
      this.isViewMode = 1;
    }

    if (isMobile) {
      this.isViewMode = 2;
    }
  }

  ngOnInit() {
    if(isPlatformBrowser(this.platformId)) {
      this.checkDeviceType();
    }

    this.contentService.loadContentAll();

    this.sharingService.changeEmitted.subscribe(
      data => {
        if (data.hasOwnProperty('articlePopupOpened')) {
          this.articlePopupActive = true;
          this.activeArticleId = data.articleId;

          this.mapActive = false;

          window.scroll(0, 0);
        }

        if (data.hasOwnProperty('articlePopupClosed')) {
          this.articlePopupActive = false;
        }

        if (data.hasOwnProperty('showLevelOne')) {
          this.mapActive = false;
        }

        if (data.hasOwnProperty('hideLevelOne')) {
          this.articlePopupActive = false;
          this.quizPopupActive = false;

          this.mapActive = true;
        }

        if (data.hasOwnProperty('quizPopupOpened')) {
          this.quizPopupActive = true;
          this.quizData = data.quizData;

          this.mapActive = false;

          window.scroll(0, 0);
          let body = <HTMLElement>document.getElementsByTagName('body')[0];
          body.style.overflow = 'hidden';
        }

        if (data.hasOwnProperty('quizPopupClosed')) {
          this.quizPopupActive = false;
          let body = <HTMLElement>document.getElementsByTagName('body')[0];
          body.style.overflow = 'auto';
        }
      });
  }

  onMainMenuOpened(event) {
    let currentBody = document.getElementsByTagName('body')[0];
    this.mainnavActive = event;
    if (this.mainnavActive) {
      currentBody.style.overflow = 'hidden';
    } else {
      currentBody.style.overflow = 'auto';
    }
  }

  onMenuLinkClicked() {
    let currentBody = document.getElementsByTagName('body')[0];
    this.mainnavActive = false;
    currentBody.style.overflow = 'auto';
  }

  setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
}
