import {Component, OnInit} from '@angular/core';
import {ContentService} from "../../services/content/content.service";
import {Router} from "@angular/router";
import {encode} from "punycode";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'wu-wuerm-overview',
  templateUrl: './wuerm-overview.component.html',
  styleUrls: ['./wuerm-overview.component.scss']
})
export class WuermOverviewComponent implements OnInit {
  tracks;
  stations;
  pagetitle;

  constructor(
    private ContentService: ContentService,
    private router: Router,
    private titleService: Title
  ) {
  }

  ngOnInit() {
    this.tracks = this.ContentService.contentAll.tracks;
    this.stations = this.ContentService.contentAll.stationsOverview;
    this.setTitle('Überblick - Würmlehrpfad');
  }

  onAccordionHeaderClick(event) {
    let clickedAccordionHeader = event.target,
      accordionParent = clickedAccordionHeader.closest('.accordion'),
      accordionContent = accordionParent.querySelector('.accordion--content');

    if (accordionContent !== undefined && accordionContent !== null) {
      if (accordionContent.classList.contains('is-visible')) {
        clickedAccordionHeader.classList.remove('is-visible');
        accordionContent.classList.remove('is-visible');
      } else {
        clickedAccordionHeader.classList.add('is-visible');
        accordionContent.classList.add('is-visible');
      }
    }
  }

  onStationOpenClick(event) {
    let clickedStation = event.target,
      stationId = clickedStation.dataset.stationid,
      stationName = clickedStation.dataset.stationname;

      this.setTitle(stationName + ' - Würmlehrpfad');


    if (clickedStation !== undefined && clickedStation !== null) {
      this.router.navigate(['/article/'+this.slugify(stationName)], {queryParams: {stationid: stationId}});
    }
  }

  setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

   slugify(string) {
    const a = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœøṕŕßśșțùúüûǘẃẍÿź·/_,:;';
    const b = 'aaaaaaaaceeeeghiiiimnnnooooooprssstuuuuuwxyz------';
    const p = new RegExp(a.split('').join('|'), 'g');
    return string.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with ‘and’
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
  }

}
