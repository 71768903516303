import {Injectable} from "@angular/core";
import {HttpClient} from '@angular/common/http'

@Injectable()

export class SearchApiService {

  constructor(
    private http: HttpClient
  ) {
  }

  getResults(text: string) {
    return this.http.get("https://api.wuermentdecken.de/search?token=HL4a2xCxRDkvyVFHoLHsYP3suyqPhg9eiorpy1Dwsgw&text=" + text);
  }
}
