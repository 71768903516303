import {Component} from "@angular/core";
import {ContentService} from "../services/content/content.service";
import {Title} from "@angular/platform-browser";


@Component({
  selector: 'wu-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})

export class InfoComponent {
  informations;

  constructor(
    private ContentService: ContentService,
    private titleService: Title
  ) {
  }

  ngOnInit() {
    this.informations = this.ContentService.contentAll.informations;
    this.setTitle('Weiterführende Informationen - Würmlehrpfad')
  }

  setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }


}
