import {Component, Input, OnInit} from '@angular/core';
import {SharingService} from "../../services/system/sharing.service";

@Component({
  selector: 'wu-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {

  @Input() quizData: any;

  quizPageActive: number = 1;

  quizPageAnsweredActive: boolean = false;
  quizPageAnsweredRight: boolean = false;
  quizPageAnsweredWrong: boolean = false;

  constructor(private sharingService: SharingService) {
  }

  ngOnInit() {
  }

  onAnswerClicked(event) {
    let clickedAnswer = event.target,
      parentQuizPage = <HTMLElement>clickedAnswer.closest('.quiz__page');

    if (parentQuizPage !== undefined && parentQuizPage !== null) {
      let correctAnswer = parseInt(parentQuizPage.dataset.correctanswer, 10);

      if (parseInt(clickedAnswer.value, 10) === correctAnswer) {
        this.quizPageAnsweredActive = true;

        this.quizPageAnsweredRight = true;
        this.quizPageAnsweredWrong = false;
      } else {
        this.quizPageAnsweredActive = true;

        this.quizPageAnsweredRight = false;
        this.quizPageAnsweredWrong = true;
      }
    }
  }

  onNextQuestionClick() {
    this.quizPageActive++;
    this.quizPageAnsweredActive = false;
    this.quizPageAnsweredRight = false;
    this.quizPageAnsweredWrong = false;
  }

  onClosePopup() {
    let sharingData = {
      quizPopupClosed: true
    };
    this.sharingService.emitChange(sharingData);
  }

}
