import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {sha256, sha3} from "./sha256";

let getAppId = function (): string {
  return 'poi-map-web';
};

const HOST = 'https://ep.muenchenapis.de';

@Injectable()

export class EchtzeitproxyService {
  mvStations;
  private ak;

  constructor(private _http: HttpClient) {
    this.ak = sha3("ssqp0ro8q0755q9735ons1p80n528rs039n43nro");
  }

  private static extractData(placesIn: object[]) {
    let results = [];

    for (let idx in placesIn) {
      let place = placesIn[idx];

      let address = place['street'];
      if (place['streetNumber']) {
        address += " " + place['streetNumber'];
      }

      results.push(
        [place['location'].lat, place['location'].lng],
        place['urlInPortal'],
        place['id'],
        place['title'],
        place['urlInPortal'],
        place['showLabel'],
        (place['categoryIconStatic'] ? place['categoryIconStatic'] : place['categoryIconBaseUrl']),
        place['images'],
        place['city'],
        address,
        place['categories'],
        place['isProduct'],
        (place['mobilityStation'] ? place['mobilityStation'] : null)
      );
    }

    return results;
  }

  public getCategoryPlacesForBoundingBox(category: string, box, zoomLevel: number) {
    let endpoint: string = '/api/v3/categories/' + category + '/places/boundingbox.json';

    zoomLevel = Math.round(zoomLevel);

    let lat1: number = box.getNorth(),
      lng1: number = box.getWest(),
      lat2: number = box.getSouth(),
      lng2: number = box.getEast();

    let params = new HttpParams()
      .set('minLat', (lat1 < lat2 ? lat1 : lat2).toString())
      .set('maxLat', (lat1 > lat2 ? lat1 : lat2).toString())
      .set('minLng', (lng1 < lng2 ? lng1 : lng2).toString())
      .set('maxLng', (lng1 > lng2 ? lng1 : lng2).toString())
      .set('customersPreferred', 'true')
      .set('includeImages', 'listPictures750,listPictures178')
      .set('zoomLevel', zoomLevel.toString())
      .set('appId', getAppId());
    params = this.setBoundingBoxOptParams(params);

    return this._http
      .get(HOST + endpoint, this.getOpts('GET', endpoint, params, this.ak));
  }

  public getMvDepartures(mvStation: string) {
    let endpoint: string = '/api/v3/mvg/departures/' + mvStation + '.json';

    let params = new HttpParams()
      .set('appId', getAppId());

    return this._http
      .get(HOST + endpoint, this.getOpts('GET', endpoint, params, this.ak));
  }

  public getOpts(method: string, url: string, params: HttpParams, sec: string) {
    let complete = url + "?" + params.toString();
    let base = method.toUpperCase() + complete + sec;

    let newParams: HttpParams = params.set("signature", sha256(base));

    return {
      'params': newParams,
      //'headers': authHeader
    };
  }

  private setBoundingBoxOptParams(params: HttpParams): HttpParams {
    let zoomParams = window.location.hash.match(/zoom\-(\d+)\-places\-(\d+)/);
    if (zoomParams) {
      return params.set('zoomLevelDistributorTileplaces', zoomParams[2])
        .set('zoomLevelDistributorTilediff', zoomParams[1]);
    } else {
      return params;
    }
  }
}
